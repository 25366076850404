<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />

    <div v-else
        class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between
         lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8"
        style="background: #FDFFFC;">
      <div class="table-container lg:tw-pr-8">
        <div class="tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center">
          <p class="table-header" :style="{ color: companyColor }">
            All Managers
          </p>
          <div class="tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center">
            <div
                class="mr-2"
                style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
            >
              <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
              >
                <template #prepend>
                  <v-icon :color="companyColor" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="companyColor">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>

        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="managers.length <= 10"
            :items="managers"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
        >
          <template #header.sno>
            <span class="status">S/N</span>
          </template>
          <template v-slot:[`item.sno`]="{ item }">
            <span class="td-style">{{ item.sno }}</span>
          </template>
          <template #header.profileImage>
            <span class="status">Image</span>
          </template>
          <template v-slot:[`item.profileImage`]="{ item }">
            <img
                :src="item.profileImage"
                alt="terminal"
                v-if="item.profileImage"

            />
            <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
                @click="viewSingle(item.id)"
            >
              <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                {{ item.firstname[0] }}
              </p>
            </div>
          </template>
          <template #header.firstname>
            <span class="status">First Name</span>
          </template>
          <template v-slot:[`item.firstname`]="{ item }">
            <span class="td-style">{{ item.firstname }}</span>
          </template>

          <template #header.lastname>
            <span class="status">Last Name</span>
          </template>
          <template v-slot:[`item.lastname`]="{ item }">
            <span class="td-style">{{ item.lastname }}</span>
          </template>
          <template #header.email>
            <span class="status">Email</span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span class="td-style">{{ item.email }}</span>
          </template>
          <template #header.mobile>
            <span class="status">Mobile</span>
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            <span class="td-style">{{ item.phoneNumber }}</span>
          </template>
          <template #header.country>
            <span class="status">Country</span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span class="td-style">{{ item.address.country }}</span>
          </template>
          <template #header.terminal>
            <span class="status">Terminal</span>
          </template>
          <template v-slot:[`item.terminal`]="{ item }">
            <span class="td-style">{{ item.terminalAssignedName }}</span>
          </template>
          <template #header.actions>
            <span class="status"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex justify-center">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-list>
                <v-list-item class="" @click="viewSingle(item)">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer menu-text tw-items-center">
                    View
                    <v-icon :color="companyColor">mdi-arrow-right</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-col>
</template>

<script>
import Loader from "../../reuseables/Loader";
import {getCompanyManagers} from "../../../services/api/APIService";
export default {
  name: "TerminalManagerAllManager",
  components: {Loader},
  data() {
    return {
      companyData: {},
      search: "",
      companyColor: "#004AAD",
      singleSelect: false,
      headers: [
        {text: "", value: "sno"},
        { text: "", value: "profileImage" },
        { text: "", value: "firstname" },
        { text: "", value: "lastname" },
        { text: "", value: "email" },
        { text: "", value: "mobile" },
        { text: "", value: "country" },
        { text: "", value: "terminal" },
        { text: "", value: "actions", align: "end" },
      ],
      managers: [],
      selectedRow: [],
      not_found: "Terminal managers will display here",
      loading: false,
    };
  },
  methods: {
    viewSingle(manager) {
      this.$store.dispatch(
        "terminalManagerManagerComponent/setCurrentComponent",
        "singleManager"
      );
      sessionStorage.setItem(
        "terminalManagerManagerComponent",
        "singleManager"
      );
      sessionStorage.setItem("managerId",manager.id)
    },
    async getCompanyManagers() {
      this.loading = true;
      try {
        const response = await getCompanyManagers(this.companyData.id);
        this.managers = response.data;
        this.mostRecent = this.managers[this.managers.length - 1];
        this.managers.forEach((manager, index) => manager.sno = index+1)
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    }
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getCompanyManagers();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}


::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
.menu-text{
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;

}
</style>
