<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllManagers from "./AllManager";
import SingleManager from "./SingleManager";
import { mapGetters } from "vuex";
export default {
  name: "TerminalManagerManagers",
  computed: {
    ...mapGetters("terminalManagerManagerComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allManagers") {
        return AllManagers;
      } else if (component === "singleManager") {
        return SingleManager;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("terminalManagerManagerComponent");

    if (!component) {
      sessionStorage.setItem("terminalManagerManagerComponent", "allManagers");
      this.$store.dispatch(
        "terminalManagerManagerComponent/setCurrentComponent",
        "allManagers"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerManagerComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
