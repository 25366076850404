<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="main-body" v-else>
      <v-col sm="12" class="tw-flex tw-justify-between tw-items-center">
        <button
            class="tw-flex tw-items-center overview-btn tw-mt-14 lg:tw-mt-6"
            @click="backToAllManagers">
          <icons name="arrow-back" :color="companyColor" />

          <span class="tw-ml-4" :style="{ color: companyColor }">All Managers</span>
        </button>

      </v-col>

      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16">
        <div class="lg:tw-px-12">
          <div class="admin-img-container">
            <img
                :src="managerData.profileImage"
                :alt="managerData.firstname"
                v-if="managerData.profileImage"
            />
          </div>
        </div>

        <v-row class="tw-mt-6 lg:tw-px-12 tw-justify-between tw-flex-col md:tw-flex-row">
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">
                {{ managerData.firstname }}
              </v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{managerData.lastname}}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.email
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Mobile</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.phoneNumber
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.terminalAssignedName
                }}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.address.houseNumber
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{managerData.address.streetName}}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Town/City</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.address.city
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">State</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.address.state
                }}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
              <v-col sm="6" class="label-value tw-m-0 tw-p-0">{{
                  managerData.address.country
                }}</v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import {getManagerById} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import Icons from "../../reuseables/Icons";

export default {
  name: "TerminalManagersingleManager",
  components: {Icons, Loader},
  data() {
    return {
      companyData: {},
      managerData: {},
      companyColor: "#004AAD",
      loading: false,
      managerId: "",
    };
  },
  methods: {
    backToAllManagers() {
      this.$store.dispatch(
        "terminalManagerManagerComponent/setCurrentComponent",
        "allManagers");
      sessionStorage.setItem("terminalManagerManagerComponent", "allManagers");
    },
    async getManagerById() {
      try {
        const response = await getManagerById(
            this.companyData.id,
            this.managerId);
        this.managerData = await response.data;
        this.loading = false;
        console.log(response);
      } catch (error) {
        console.log(error.response);
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let managerId = sessionStorage.getItem("managerId");
    if (managerId) {
      this.managerId = managerId;
    } else {
      return this.backToAllManagers();
    }
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getManagerById();
  },
};
</script>

<style lang="scss" scoped>
.admin-img-container {
  width: 200px;
  height: 220px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.label-key {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
